import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import DialogBeneficiarios from "../Dialogs/DialogVerBeneficiarios";
import TXTField from "../../../Tools/TextField/TextField";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { useState } from "react";
import { useForm } from "react-hook-form";
import "../../../../css/estilo.css";
import DialogAsegurado from "../Dialogs/DialogVerAsegurado";
import { getDataToken, putDataToken } from "../../../../api/dataToken";
import {
  formatNumberDecimalComa,
  formatedNumberAndDecimalAndComa,
  validateNumberAndDecimal,
} from "../../../../utils/functions";
import { useEffect } from "react";
import {
  TOTAL_CONTRA_ACC_RESERVA,
  TOTAL_FAVOR_ACC_RESERVA,
  TOTAL_NETO_ACC_RESERVA,
} from "../../../../utils/constanstoken";
import { Select1 } from "../../../Tools/Selectss/Select1";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CardPoliza(props) {
  const { register, handleSubmit } = useForm();
  const [expanded, setExpanded] = React.useState(false);
  const [editForm, setEditForm] = useState(true);
  const poliza = props.poliza;

  console.log(poliza)
  const idFolio = props.idFolio;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const editFormClick = () => {
    setEditForm(false);
  };
  const sendForm = (data) => {};

  //Funcionalidad para modificar los valores preliminares de los accesorios de la reserva (sin asiento contable)
  const [accesorios, setAccesorios] = useState([]); //Arreglo original
  const [txtValuesAccesorios, setTxtValuesAccesorios] = useState([]); // Arreglo de los valores que se modifican
  const [txtValueTotal, setTxtValueTotal] = useState("");
  const [txtValueTotalErr, setTxtValueTotalErr] = useState(false);
  const [listPCP, setListPCP] = useState([]);
  const [listCober, setListCober] = useState([]);
  const [cobertura, setCobertura] = useState(false);
  const [btnSave, setBtnSave] = useState(false);
  const [btnSaveLabel, setBtnSaveLabel] = useState("Guardar");
  let dataPCP 

  //Validar si ya existen registros de reservas ajustes para este folio y poliza
  const getDataPolCoberProd = async () => {
    try {
      dataPCP = await getDataToken(
        "coberturaspolizasportramite/get_folio/" +
          poliza.RECT_idfolio.RECT_idfolio +
          "/"
      ); //cambiar por un filtro por folio en api

      if (dataPCP) {
        console.log(dataPCP)
        if (dataPCP.length > 0) {
          setListPCP(dataPCP);
          let filterPolCob = dataPCP.filter(
            (obj) =>
              obj.POL_idpoliza.POL_idpoliza == poliza.POL_idpoliza.POL_idpoliza
          );
          let copiaApi = [];
          if (filterPolCob.length > 0) {
            copiaApi = filterPolCob.map((obj) => {
              return {
                ...obj,
                identificador: obj.COB_idcobertura.COB_idcobertura,
                texto: obj.COB_idcobertura.COB_cobertura,
              };
            });
          }
          setListCober(copiaApi);
        }
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    // getDataAccesorios();
    getDataPolCoberProd();
  }, []);
  React.useEffect(() => {
    const handleChangeCob = async () => {
      if (cobertura) {
        let findCobPCP = listPCP.find(
          (obj) => obj.COB_idcobertura.COB_idcobertura == cobertura
        );

        if (findCobPCP) {
          let resp = await getDataToken(
            "reservasporpolizaporcoberturas/get_IDPCP/" +
              findCobPCP.CPT_idcoberturapolizatramite +
              "/"
          );
          console.log(resp);
          if (resp.length > 0) {
            // Existe un registro de reserva
            let dataReserAjus = await getDataToken(
              "reservaajustes/get_listado/"
            ); //cambiar por un filtro por folio en id PCP
            let filtroPCP = dataReserAjus.filter(
              (e) => e?.RPC_idreservapolizacob?.id == resp[0].id
            );
            setAccesorios(filtroPCP);
            setTxtValuesAccesorios(filtroPCP);
            let summation = addAccValues(filtroPCP);
            setTxtValueTotal(summation);
          }
        }
      }
    };
    handleChangeCob();
  }, [cobertura]);

  //Funcion que detecta los cambios en los accesorios
  const handleInputAccChange = (e, id, obj) => {
    if (e.target.value === "-") {
      return false;
    }
    const formattedValue = formatedNumberAndDecimalAndComa(e);
    // const newDataTxtAcc = [...txtValuesAccesorios]; // Crear una copia nueva del array de objetos
    const newDataTxtAcc = JSON.parse(JSON.stringify(txtValuesAccesorios)); // Crear una copia más profunda del array de objetos
    newDataTxtAcc.forEach((c, i) => {
      if (c.id === id) {
        c.REAJ0_importe = formattedValue;
      }
    });
    setTxtValuesAccesorios(newDataTxtAcc);
  };
  //UseEffect para validar la sumatoria entre los accesorios
  useEffect(() => {
    let suma = addAccValues(txtValuesAccesorios);
    if (suma < 0) {
      setTxtValueTotalErr(true);
    } else {
      setTxtValueTotalErr(false);
    }
    setTxtValueTotal(formatNumberDecimalComa(suma));
  }, [txtValuesAccesorios]);

  // Funcion para sumar los accesorios al total neto
  const addAccValues = (objects) => {
    let suma = 0;
    for (let obj of objects) {
      if (
        obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == -1 ||
        obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == 1
      ) {
        suma += parseFloat(
          validateNumberAndDecimal(
            obj.REAJ0_importe.replace(/,/g, "") *
              parseInt(obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte)
          )
        );
      }
    }
    return suma.toFixed(2);
  };
  // Funcion para sumar los totales, si es tipo 1 para total a favor, 2 para total en contra
  const getAccValues = (objects, type) => {
    let summation = 0;
    for (let obj of objects) {
      if (
        obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == -1 &&
        type === 2
      ) {
        summation += parseFloat(
          validateNumberAndDecimal(
            obj.REAJ0_importe.replace(/-,/g, "") *
              parseInt(obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte)
          )
        );
      } else if (
        obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == 1 &&
        type === 1
      ) {
        summation += parseFloat(
          validateNumberAndDecimal(
            obj.REAJ0_importe.replace(/-,/g, "") *
              parseInt(obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte)
          )
        );
      }
    }
    return summation.toFixed(2);
  };
  //Funcion para realizar las actualizaciones a las apis
  const saveData = async () => {
    //Comparar cambios de los datos originales

    // ***************************EMPIEZA APARTADO PARA ACTUALIZAR VALORES ACCESORIOS ***************************
    // Recorrer el arreglo de accesorios original y compararlo con el que se hacen cambios
    for (const accesorio of accesorios) {
      //Buscar el objeto en el arreglo de cambios
      let findAcc = txtValuesAccesorios.find((obj) => accesorio.id == obj.id);
      if (
        findAcc &&
        parseFloat(findAcc.REAJ0_importe) != parseFloat(accesorio.REAJ0_importe)
      ) {
        // Realizar los put correspondientes
        //Actualizar importes en contra
        if (
          findAcc?.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == -1
        ) {
          //Actualizar importe
          let jsonImpContra = {
            REAJ0_importe: validateNumberAndDecimal(
              findAcc.REAJ0_importe.replace(/,/g, "")
            ),
          };
          let dataAcc = await putDataToken(
            `reservaajustes/get_id/${accesorio.id}/`,
            jsonImpContra
          );
          if (!dataAcc) {
            console.error("Algo ha salido mal.");
          }
        } //Actualizar importes a favor
        else if (
          findAcc?.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == 1
        ) {
          //Actualizar importe
          let jsonImpFavor = {
            REAJ0_importe: validateNumberAndDecimal(
              findAcc.REAJ0_importe.replace(/,/g, "")
            ),
          };
          let dataAcc = await putDataToken(
            `reservaajustes/get_id/${accesorio.id}/`,
            jsonImpFavor
          );
          if (!dataAcc) {
            console.error("Algo ha salido mal.");
          }
        }

        //Buscar el objeto que corresponde al valor neto
        let findTotalNeto = accesorios.find(
          (obj) =>
            obj.CREVAJ0_idconceptoreservaajuste
              .CREVAJ0_idconceptoreservaajuste == TOTAL_NETO_ACC_RESERVA.id
        );
        if (findTotalNeto) {
          //Actualizar importe Total neto
          let jsonTotalNeto = {
            REAJ0_importe: addAccValues(txtValuesAccesorios),
          };
          let dataTotalNeto = await putDataToken(
            `reservaajustes/get_id/${findTotalNeto.id}/`,
            jsonTotalNeto
          );
          if (!dataTotalNeto) {
            console.error("Algo ha salido mal.");
          } else {
            console.log("Total neto:", dataTotalNeto);
          }
        }
        //Buscar el objeto que corresponde al valor a favor
        let findTotalFavor = accesorios.find(
          (obj) =>
            obj.CREVAJ0_idconceptoreservaajuste
              .CREVAJ0_idconceptoreservaajuste == TOTAL_FAVOR_ACC_RESERVA.id
        );
        if (findTotalFavor) {
          //Actualizar importe a favor
          let jsonTotalNeto = {
            REAJ0_importe: getAccValues(txtValuesAccesorios, 1),
          };
          let dataTotalFavor = await putDataToken(
            `reservaajustes/get_id/${findTotalFavor.id}/`,
            jsonTotalNeto
          );
          if (!dataTotalFavor) {
            console.error("Algo ha salido mal.");
          } else {
            console.log("Total a favor:", dataTotalFavor);
          }
        }
        //Buscar el objeto que corresponde al valor en contra
        let findTotalContra = accesorios.find(
          (obj) =>
            obj.CREVAJ0_idconceptoreservaajuste
              .CREVAJ0_idconceptoreservaajuste == TOTAL_CONTRA_ACC_RESERVA.id
        );
        if (findTotalContra) {
          //Actualizar importe en contra
          let jsonTotalNeto = {
            REAJ0_importe: getAccValues(txtValuesAccesorios, 2),
          };
          let dataTotalContra = await putDataToken(
            `reservaajustes/get_id/${findTotalContra.id}/`,
            jsonTotalNeto
          );
          if (!dataTotalContra) {
            console.error("Algo ha salido mal.");
          } else {
            console.log("Total en contra:", dataTotalContra);
          }
        }
        //Modificar el valor de calculo ajustes en reservasporpolizaporcoberturas
        let jsonReserPolCob = {
          RPC_calculoAjustes: addAccValues(txtValuesAccesorios),
        };
        let dataCalculoRes = await putDataToken(
          `reservasporpolizaporcoberturas/get_id/${accesorio.RPC_idreservapolizacob.id}/`,
          jsonReserPolCob
        );
        if (!dataCalculoRes) {
          console.error("Algo ha salido mal.");
        } else {
          console.log("Calculo reserva:", dataCalculoRes);
        }

        // //Actualizar importes accesorios
        // let jsonAcc = {
        //   REAJ0_importe: findAcc.REAJ0_importe
        // };
        // let dataAcc = await putDataToken(`reservaajustes/get_id/${accesorio.id}/`, jsonAcc);
        // if (!dataAcc) {
        //   console.error("Algo ha salido mal.");
        // }
      }
    }
    setBtnSave(false);
    setBtnSaveLabel("Guardar");
  };
  // ***************************TERMINA APARTADO PARA ACTUALIZAR VALORES ACCESORIOS ***************************

  return (
    <>
      <Card sx={{ width: "100%" }}>
        <CardHeader
          onClick={handleExpandClick}
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              style={{ color: "#fff" }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
          style={{
            backgroundColor: "#00203b",
            color: "#fff",
            cursor: "pointer",
          }}
          title={
            <h1-header-card-polizas>
              Número de póliza {poliza.POL_idpoliza.POL_numpoliza}
            </h1-header-card-polizas>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {/* Propuesta */}
            <Grid container>
              <form onSubmit={handleSubmit(sendForm)}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={4}>
                    <h3-title-card>Número de Póliza:</h3-title-card>
                    <TXTField
                      size="small"
                      defaultValue={poliza?.POL_idpoliza?.POL_numpoliza}
                      {...register("txt_numPoliza")}
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>Moneda:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_moneda")}
                      defaultValue={
                        poliza?.POL_idpoliza?.MON_idmonedas?.MON_abreviatura
                      }
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>Estatus en sistema:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_estatusSistema")}
                      defaultValue={
                        poliza?.POL_idpoliza?.EST_idestatuspoliza
                          ?.EST_descripcion
                      }
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>Número de siniestro:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_numSinies")}
                      defaultValue={poliza?.SIN_idsiniestro?.SIN_numerosiniestro}
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>Producto:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_producto")}
                      defaultValue={poliza?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion}
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>Tipo de producto:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_tipoProducto")}
                      defaultValue={poliza?.POL_idpoliza?.PRO_idproductos?.PROT_idtiposproducto?.PROT_tipoproducto}
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>Canal de distribución:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_canalDist")}
                      defaultValue={
                        poliza?.POL_idpoliza?.CHN_idcanales
                          ?.CHN_canaldedistribucion
                      }
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>Cobertura reclamada:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_coberturaRec")}
                      defaultValue={dataPCP ? dataPCP[0]?.COB_idcobertura?.COB_cobertura : " "}
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>Suma asegurada:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_sumaAsegurada")}
                      defaultValue={dataPCP ? dataPCP[0]?.CPT_sumaasegurada : " "}
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>
                      Suma asegurada adicional (IMA, DIBA):
                    </h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_sumaAseguradaAdi")}
                      defaultValue={dataPCP ? dataPCP[0]?.CPT_sumaasegurada : " "}
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>Fecha de emisión:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_fechaEmision")}
                      defaultValue={poliza?.POL_idpoliza?.POL_polizafecemisión}
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>Pagado hasta:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_pagadoHasta")}
                      defaultValue={poliza?.POL_idpoliza?.POL_polizapahasta}
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>Antigüedad:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_antiguedad")}
                      defaultValue={
                        poliza?.POL_idpoliza?.POL_aniosduracionpoliza
                      }
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h3-title-card>Fecha de último pago:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_fechaUltimoPago")}
                      defaultValue={""}
                      disabled={editForm}
                    />
                  </Grid>

                  <Divider />

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={2}
                      style={{ textAlign: "left", padding: "20px" }}
                    >
                      <h3-title-card>Cobertura</h3-title-card>
                    </Grid>
                    <Grid item style={{ textAlign: "left", padding: "20px" }}>
                      <Select1
                        label="Coberturas"
                        array={listCober}
                        onChange={(e) => setCobertura(e.target.value)}
                      />{" "}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="column"
                    alignItems="center"
                    style={{ textAlign: "right", paddingBottom: "100px" }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "left", padding: "20px" }}
                    >
                      <h3-title-card>Importes a favor</h3-title-card>
                    </Grid>
                    {/* Aqui los resultados de la api accesorios a favor */}
                    {txtValuesAccesorios.map((element, index) => {
                      if (
                        element?.CREVAJ0_idconceptoreservaajuste
                          ?.CREVAJ0_tipoimporte == 1
                      ) {
                        return (
                          <>
                            <Grid item xs={5}>
                              <h3-title-card>{`(+) ${element.CREVAJ0_idconceptoreservaajuste.CREVAJ0_conceptoreservaaj} :`}</h3-title-card>
                            </Grid>
                            <Grid item xs={3} className="dictamen-polizas-txf">
                              <TXTField
                                size="small"
                                startAdornment={true}
                                showStartSimboloPeso={true}
                                textAlign="right"
                                {...register(`txt_favor_${index}`)}
                                defaultValue={0}
                                disabled={editForm}
                                value={
                                  txtValuesAccesorios.id ||
                                  (parseFloat(element.REAJ0_importe) < 0
                                    ? (
                                        parseFloat(element.REAJ0_importe) * -1
                                      ).toFixed(2)
                                    : element.REAJ0_importe)
                                }
                                onChange={(e) =>
                                  handleInputAccChange(e, element.id, element)
                                }
                              />
                            </Grid>
                          </>
                        );
                      }
                    })}
                    <Divider />

                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "left", padding: "20px" }}
                    >
                      <h3-title-card>Importes a descontar</h3-title-card>
                    </Grid>

                    {/* Aqui los resultados de la api accesorios a descontar */}
                    {txtValuesAccesorios.map((element, index) => {
                      if (
                        element?.CREVAJ0_idconceptoreservaajuste
                          ?.CREVAJ0_tipoimporte == -1
                      ) {
                        return (
                          <>
                            <Grid item xs={5}>
                              <h3-title-card>{`(-) ${element.CREVAJ0_idconceptoreservaajuste.CREVAJ0_conceptoreservaaj} :`}</h3-title-card>
                            </Grid>
                            <Grid item xs={3} className="dictamen-polizas-txf">
                              <TXTField
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /-/g,
                                    ""
                                  );
                                }}
                                size="small"
                                startAdornment={true}
                                showStartSimboloPeso={true}
                                textAlign="right"
                                {...register(`txt_contra_${index}`)}
                                defaultValue={0}
                                disabled={editForm}
                                value={
                                  txtValuesAccesorios.id ||
                                  (parseFloat(element.REAJ0_importe) < 0
                                    ? (
                                        parseFloat(element.REAJ0_importe) * -1
                                      ).toFixed(2)
                                    : element.REAJ0_importe)
                                }
                                onChange={(e) =>
                                  handleInputAccChange(e, element.id, element)
                                }
                              />
                            </Grid>
                          </>
                        );
                      }
                    })}
                    <Divider />

                    <Grid item xs={5}>
                      <h3-title-card>Total a pagar:</h3-title-card>
                    </Grid>
                    <Grid item xs={3} className="dictamen-polizas-txf">
                      <TXTField
                        size="small"
                        startAdornment={true}
                        showStartSimboloPeso={true}
                        error={txtValueTotalErr}
                        textAlign="right"
                        {...register("txt_totalPagar")}
                        defaultValue={""}
                        disabled={editForm}
                        value={txtValueTotal}
                      />
                    </Grid>
                  </Grid>
                  <Divider />

                  <Grid item xs={6}>
                    <h3-title-card>Estatus del dictamen:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_estatusDictamen")}
                      defaultValue={""}
                      disabled={editForm}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <h3-title-card>Estatus de la operación:</h3-title-card>
                    <TXTField
                      size="small"
                      {...register("txt_estatusOperacion")}
                      disabled={editForm}
                    />
                  </Grid>
                  <Grid
                    container
                    justifyContent="right"
                    alignItems="right"
                    spacing={2}
                    marginTop={1}
                  >
                    <Grid item>
                      <ButtonVino
                        label={"Editar"}
                        color="secondary"
                        onClick={editFormClick}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonVino
                        label={btnSaveLabel}
                        type="submit"
                        onClick={() => {
                          setBtnSave(true);
                          setBtnSaveLabel("Guardando...");
                          saveData();
                        }}
                        estatus={btnSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <DialogAsegurado
                        mainButton={"Ver asegurado"}
                        title={`Asegurado de la póliza `}
                        secondButton={"Cancelar"}
                        idPoliza={poliza?.POL_idpoliza?.POL_idpoliza}
                        idFolio={props.idFolio}
                      />
                    </Grid>
                    <Grid item>
                      <DialogBeneficiarios
                        mainButton={"Ver beneficiarios"}
                        title={`Beneficiarios de la póliza `}
                        secondButton={"Cancelar"}
                        idPoliza={poliza?.POL_idpoliza?.POL_idpoliza}
                        idFolio={props.idFolio}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}

export function calcularEdadDeContratacion(fechaNacimiento, fechaEmisionPoliza) {

    const nacimiento = new Date(fechaNacimiento);
    const emision = new Date(fechaEmisionPoliza);


    let edad = emision.getFullYear() - nacimiento.getFullYear();

    const mesNacimiento = nacimiento.getMonth();
    const mesEmision = emision.getMonth();


    if (mesEmision < mesNacimiento || (mesEmision === mesNacimiento && emision.getDate() < nacimiento.getDate())) {
        edad--;
    }

    return edad;
}

export function calcularEdadDeSiniestro(fechaNacimiento, fechaSiniestro) {

    const nacimiento = new Date(fechaNacimiento);
    const siniestro = new Date(fechaSiniestro);


    let edad = siniestro.getFullYear() - nacimiento.getFullYear();

    const mesNacimiento = nacimiento.getMonth();
    const mesSiniestro = siniestro.getMonth();


    if (mesSiniestro < mesNacimiento || (mesSiniestro === mesNacimiento && siniestro.getDate() < nacimiento.getDate())) {
        edad--;
    }

    return edad;
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Establecer idioma, evitar traducción automática y agregar meta para Google
document.documentElement.setAttribute("lang", "es"); // Configurar idioma de la página a español
document.documentElement.setAttribute("translate", "no"); // Desactivar la traducción automática

// Agregar meta tag para Google Chrome
const metaTag = document.createElement("meta");
metaTag.name = "google";
metaTag.content = "notranslate";
document.head.appendChild(metaTag);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

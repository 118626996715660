import React, { useState, useEffect } from "react";
import "../../../../css/estilo.css";
import "../Forms/Header.css";
import { Grid } from "@mui/material";
import TXTField from "../../../Tools/TextField/TextField";
import { Select1 } from "../../../Tools/Selectss/Select1";
import { FormattedMessage } from "react-intl";
import { getDataToken } from "../../../../api/dataToken";
import { LimitarDigitos } from "../Functions/Validaciones";
import { LimitarTexto, LimitarCorreo } from "../Functions/Validaciones";
import { formatNumberString } from "../../../../utils/functions";

function TramiteAgente() {
  //declaramos las siguientes variables que toman los datos que se recibieron en formulario poliza sin registro

  /*funciones CP sepomex */
  const [SEPT_Id, setSEPT_Id] = useState("");
  const [SEPT_asenta, setSEPT_asenta] = useState("");
  const [SEPT_municipio, setSEPT_municipio] = useState("");
  const [SEPT_estado, setSEPT_estado] = useState("");
  const [SEPT_ciudad, setSEPT_ciudad] = useState("");
  const [codigopostal, setCodigoPostal] = useState("");

  const [colonias, setColonias] = useState([]);
  const [dataSelectColonias, setDataSelectColonias] = useState([]);

  const [helperCP, setHelperCP] = useState("");
  //Traer los datos de CP
  const [dataContacto, setDataContacto] = useState({
    nombre: "",
    apeMat: "",
    apePat: "",
  })

  const handleChangeCP = (e) => {
    const value = e.target.value;
    console.log(value)
    setCodigoPostal(value);
    if (value.length >= 4) {
      getCodP(value)
    }
    LimitarDigitos(e.target, 6);
  };

  const getCodP = async (cp) => {
    try {
      var result = await getDataToken(`sepomex/get_listado_cp/${cp}`);
      console.log("Resultado del cp =>", result);
      setColonias(result);
      if (result <= 0) {
        setHelperCP("No hay Información.")
        setDataSelectColonias([])
        setSEPT_estado("");
        setSEPT_municipio("");
        setSEPT_asenta("");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getInfoasentamiento = (e) => {
    const dataColonia = parseInt(e.target.value);
    colonias.forEach((element) => {
      if (dataColonia === element.id) {
        setSEPT_Id(element.id);
        setSEPT_municipio(element.SEP_municipio);
        setSEPT_estado(element.SEP_estado);
        setSEPT_ciudad(element.SEP_ciudad);
        setSEPT_asenta(element.SEP_asenta);
      }
    });
  };

  console.log("Id sepomex primero" + SEPT_Id);

  //Esta función useEffect se activará cuando haya cambios en el estado 'colonias', activando a su ves otro estado
  useEffect(() => {
    console.log(colonias);
    if (colonias.length > 0) {
      let dataColonias = colonias
      dataColonias.map(
        (cp) => (
          (cp.texto = cp.SEP_asenta), // cp.texto es una propiedad que no existe dentro del JSON, por tanto, se crea
          (cp.identificador = cp.id)
        )
      ); //Se actualiza el valor del dataSelectColonias
      setDataSelectColonias(dataColonias)
    }
  }, [colonias]);


  return (

    <Grid container direction="row"
      justifyContent="flex-start"
      alignItems="center" spacing={1}>
      {/*campos a llenar para beneficiario */}
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="globNombreDelAgente"
              defaultMessage="Nombre"
            />
          }
          id="txtNombre"
          name="txtNombre"
          value={dataContacto.nombre}
          onChange={(e) => {
            setDataContacto(prevState => ({ ...prevState, nombre: formatNumberString(e.target.value, 18, true, 3) }));
          }}
          required
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="globNombreDelAgente"
              defaultMessage="Apellido paterno"
            />
          }
          id="txtApePat"
          name="txtApePat"
          value={dataContacto.apePat}
          onChange={(e) => {
            setDataContacto(prevState => ({ ...prevState, apePat: formatNumberString(e.target.value, 18, true, 3) }));
          }}
          required
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="globNombreDelAgente"
              defaultMessage="Apellido materno"
            />
          }
          id="txtApeMat"
          name="txtApeMat"
          value={dataContacto.apeMat}
          onChange={(e) => {
            setDataContacto(prevState => ({ ...prevState, apeMat: formatNumberString(e.target.value, 18, true, 3) }));
          }}
          required
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="globClaveD"
              defaultMessage="Clave del agente"
            />
          }
          name="txtClaveAgente"
          id="txtClaveAgente"
          required
          onInput={(e) => LimitarDigitos(e.target, 8)}
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label="D.A"
          name="txtDA"
          id="txtDA"
          required
        />
      </Grid>

      {/*campos a llenar para beneficiario  correo*/}

      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="comuCorreo"
              defaultMessage="Correo eléctronico"
            />
          }
          id="txtCorreo"
          name="txtCorreo"
          required
          onInput={(e) => LimitarCorreo(e.target, 50)}
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="recepTelfi"
              defaultMessage="Teléfono fijo"
            />
          }
          name="txtTelFijo"
          id="txtTelFijo"
          onInput={(e) => LimitarDigitos(e.target, 10)}
          required
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="recepTelCe"
              defaultMessage="Teléfono celular"
            />
          }
          name="txtTelCel"
          id="txtTelCel"
          onInput={(e) => LimitarDigitos(e.target, 10)}
          required
        />
      </Grid>

      {/*campos de direccion */}
      {/* en este grid se escribira el codigo postal y posteriormente lo buscaremos en el boton buscar*/}
      <Grid item xs={3}>
        <TXTField
          // helperText={helperCP}
          label={
            <FormattedMessage
              id="globCodPos"
              defaultMessage="Código postal"
            />
          }
          value={codigopostal}
          idIcon="iconCP"
          nameIcon="iconCP"
          name="txtCP"
          id="txtCP"
          onChange={handleChangeCP}
          onInput={(e) => LimitarDigitos(e.target, 6)}
        // showSearchIcon={true}
        // iconOnClick={handleSubmitone}

        />
      </Grid>
      <Grid item xs={3}>
        <Select1
          label={
            <FormattedMessage
              id="globSelecColoni"
              defaultMessage="Seleccionar colonia"
            />
          }
          array={dataSelectColonias}
          onChange={getInfoasentamiento}
          id="SEP_idsepomex"
          name="SEP_idsepomex"
          sx={{
            background: "white",
          }}
        />
      </Grid>


      {/*campos donde aparece la localidad cuando se busca por codigo postal*/}

      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage id="globEstado" defaultMessage="Estado" />
          }
          value={SEPT_estado}
          required
          readOnly
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="globMunicipioAlcadia"
              defaultMessage="Municipio o Alcaldia"
            />
          }
          value={SEPT_municipio}
          required
          readOnly
        />
      </Grid>

      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage id="globColoni" defaultMessage="Colonia" />
          }
          value={SEPT_asenta}
          required
          readOnly
        />
      </Grid>

      {/*datos de calle y numero */}
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage id="globCalle" defaultMessage="Calle" />
          }
          name="txtCalle"
          id="txtCalle"
          required
        />
      </Grid>

      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="recepNuExt"
              defaultMessage="No. exterior"
            />
          }
          name="txtExt"
          id="txtExt"
          onInput={(e) => LimitarDigitos(e.target, 4)}
          required
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="recepNuInt"
              defaultMessage="No. interior"
            />
          }
          name="txtInt"
          id="txtInt"
          onInput={(e) => LimitarDigitos(e.target, 4)}
          required
        />
      </Grid>
    </Grid>
  );
}

export default TramiteAgente;

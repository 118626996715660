import React from "react";
import "../../../../css/estilo.css";
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import TXTField from "../../../Tools/TextField/TextField";
import { LimitarTexto, LimitarCorreo } from "../Functions/Validaciones";
import { formatNumberString } from "../../../../utils/functions";
import { useState } from "react";


function TramiteInterno() {
const [dataContacto ,setDataContacto] = useState({
  nombre : "",
  apeMat: "",
  apePat: "",
})

  return (
    <Grid container direction="row"
      justifyContent="flex-start"
      alignItems="center" spacing={1}>
      {/*campos a llenar para internos */}
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="datosNombs"
              defaultMessage="Nombre(s)"
            />
          }
          name="txtNombre"
          id="txtNombre"
          value={dataContacto.nombre}
          onChange={(e) => {
            setDataContacto(prevState => ({ ...prevState, nombre: formatNumberString(e.target.value, 18, true, 3) }));
          }}
          required
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="datosApPat"
              defaultMessage="Apellido paterno"
            />
          }
          name="txtApePat"
          id="txtApePat"
          value={dataContacto.apePat}
          onChange={(e) => {
            setDataContacto(prevState => ({ ...prevState, apePat: formatNumberString(e.target.value, 18, true, 3) }));
          }}
          required
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="datosApMat"
              defaultMessage="Apellido materno"
            />
          }
          id="txtApeMat"
          name="txtApeMat"
          value={dataContacto.apeMat}
          onChange={(e) => {
            setDataContacto(prevState => ({ ...prevState, apeMat: formatNumberString(e.target.value, 18, true, 3) }));
          }}
          required
        />
      </Grid>
      {/*campos a llenar para beneficiario  correo*/}

      <Grid item xs={3} >
        <TXTField
          label={
            <FormattedMessage
              id="comuCorreo"
              defaultMessage="Correo eléctronico"
            />
          }
          variant="outlined"
          name="txtCorreo"
          id="txtCorreo"
          required
          onInput={(e) => LimitarCorreo(e.target, 50)}

        />
      </Grid>
    </Grid>
  );
}

export default TramiteInterno;

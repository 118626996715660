import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { TabDatosPoliza } from "./TabDatosPoliza";
import { TabDatosAsegurado } from "./TabDatosAsegurado";
import { TabDatosBeneficiarios } from "./TabDatosBeneficiario";
import { TabDatosAgente } from "./TabDatosAgente";
import { TabDatosCoberturas } from "./TabDatosCoberturas";
import { TabDatosReservas } from "./TabDatosReservas";
import {
  getDataToken,
  putDataToken,
  postDataToken,
} from "../../../../api/dataToken";
import { FormattedMessage } from "react-intl";
import { formatofecha } from "../../../../api/getUmbrales";
import {
  PERR_ASEGURADO,
  PERR_CONTRATANTE,
  PERR_BENEFICIARIO,
  PERR_AGENTE,
} from "../../../../utils/constanstoken";
import { DATA_PM } from "../../../../utils/constansPM";
import moment from "moment";
import DialogValidaciones from "../Dialog/DialogValidaciones";
import Dialogtwoboton from "../Dialog/Dialogtwoboton";
import getDate from "../../Function/obtenerFecha";
import getHours from "../../Function/obtenerHora";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ display: value === index ? "block" : "none", flexGrow: 1 }}
    >
      {value === index && (
        <Box sx={{ p: 3, width: "100%" }}>
          {" "}
          {/* Establecer el ancho al 100% */}
          <Typography style={{ width: "100%" }}>{children}</Typography>{" "}
          {/* Establecer el ancho al 100% */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function TabPM(props) {
  ////CONFIGURACIÓN DEL COMPONENTE////
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  ////////////////////////////////////////

  const numPoliza = props.numPoliza;
  console.log(numPoliza);

  const infoCharola = props.infoData;
  console.log(infoCharola);

  ///Obtener los datos del PM
  const [data, setdata] = React.useState("");

  React.useEffect(() => {
    getDataToken(`conexionpm/poliza_apiPM/?poliza=${numPoliza}`)
      .then((result) => {
        // Accede a la propiedad PromiseResult en el resultado de la API
        setdata(result[0]);
      })
      .catch((error) => console.error(error));
  }, [numPoliza]);

  console.log(data);

  const coberturas = {};

  for (
    let i = DATA_PM.ID_COBERTURA_1;
    i < DATA_PM.MONEDA_COBERTURA_10;
    i += 4
  ) {
    const index = (i - DATA_PM.ID_COBERTURA_1) / 4;
    coberturas[index] = {
      id_cobertura: data[i],
      descripcion: data[i + 1],
      sa: data[i + 2],
      id_moneda: data[i + 3],
    };
  }

  const arregloCoberturas = Object.values(coberturas);

  // Filtrar los objetos que tienen un valor en la propiedad 'id'
  const coberturasActualizar = arregloCoberturas.filter(
    (objeto) => objeto.id_cobertura !== null
  );

  const reaseguradoras = {};

  for (
    let i = DATA_PM.ID_REASEGURADORA_1;
    i < DATA_PM.ID_MONEDA_REASEGURO_5;
    i += 4
  ) {
    const index = (i - DATA_PM.ID_REASEGURADORA_1) / 4;
    reaseguradoras[index] = {
      id_reaseguradora: data[i],
      porcentaje: data[i + 1],
      importe: data[i + 2],
      id_moneda: data[i + 3],
    };
  }

  const arregloReaseguradoras = Object.values(reaseguradoras);

  // Filtrar los objetos que tienen un valor en la propiedad 'id'
  const reaseguradorasActualizar = arregloReaseguradoras.filter(
    (objeto) => objeto.id_reaseguradora !== null
  );

  const beneficiarios = {};

  for (
    let i = DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1;
    i < DATA_PM.PORCENTAJE_BENEFICIARIO_10;
    i += 3
  ) {
    if (typeof data[i] !== "undefined" && data[i] !== null) {
      const index = (i - DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1) / 3;
      const nombreCompleto = data[i]?.split(" "); // Dividir el nombre completo por espacios
      let nombre = ""; // Inicializar el nombre como una cadena vacía
      let apellidoPaterno = ""; // Inicializar el apellido paterno como una cadena vacía
      let apellidoMaterno = ""; // Inicializar el apellido materno como una cadena vacía

      const numPartes = nombreCompleto?.length;

      if (numPartes === 1) {
        // Si el nombre completo tiene solo una parte
        apellidoMaterno = nombreCompleto[0]; // La única parte es el apellido materno
      } else {
        // Si hay al menos dos partes en el nombre completo
        apellidoMaterno = nombreCompleto[numPartes - 1]; // La última parte es el apellido materno
        apellidoPaterno = nombreCompleto[numPartes - 2]; // La antepenúltima parte es el apellido paterno

        // El resto de las partes son el nombre
        nombre = nombreCompleto.slice(0, numPartes - 2).join(" ");
      }

      beneficiarios[index] = {
        nombre: nombre,
        apellidoPaterno: apellidoPaterno,
        apellidoMaterno: apellidoMaterno,
        fecha_nacimiento: data[i + 1],
        porcentaje: data[i + 2],
      };
    }
  }

  const arregloBeneficiarios = Object.values(beneficiarios);

  // Filtrar los objetos que tienen un valor en la propiedad 'id'
  const beneficiariosActualizar = arregloBeneficiarios.filter(
    (objeto) => objeto.nombre !== ""
  );

  console.log(beneficiariosActualizar);

  ////Datos Analista, hora y fecha//////
  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  console.log("fechaActSIN" + fechaActSIN);

  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");

  //Obtener usuario.
  const getUsers = async () => {
    try {
      var result = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  console.log(nombreAnalista);

  //Obtenemos la póliza que deseamos consultar.
  const [dataPoliza, setdataPoliza] = useState([]);
  const [dataCobertura, setdataCobertura] = useState([]);
  const [dataAsegurados, setdataAsegurados] = useState([]);
  const [dataContratante, setdataContratante] = useState([]);
  const [dataAgentes, setdataAgentes] = useState([]);
  const [dataBeneficiarios, setdatabeneficiarios] = useState([]);
  const [dataReseguradoras, setdataReaseguradoras] = useState([]);

  const getDatosPoliza = async () => {
    try {
      // Realizas las solicitudes para obtener los datos
      const dataPoliza = await getDataToken(
        `polizas/get_numpoliza/${numPoliza}/`
      );
      const dataCobertura = await getDataToken(
        `polizascoberturasporproductos/get_numpoliza/${numPoliza}/`
      );
      const resultPersonas = await getDataToken(`personasxpoliza/get_listado/`);
      const dataReaseguradoras = await getDataToken(
        `polizasreaseguro/get_numpoliza/${numPoliza}/`
      );
      const dataAsegurados = resultPersonas.find(
        (item) =>
          item?.POL_idpoliza?.POL_numpoliza === numPoliza &&
          item?.PER_idpersona?.PERR_idrol?.PERR_idrol === PERR_ASEGURADO.id
      );
      const dataContratante = resultPersonas.find(
        (item) =>
          item?.POL_idpoliza?.POL_numpoliza === numPoliza &&
          item?.PER_idpersona?.PERR_idrol?.PERR_idrol === PERR_CONTRATANTE.id
      );
      const dataAgentes = resultPersonas.find(
        (item) =>
          item?.POL_idpoliza?.POL_numpoliza === numPoliza &&
          item?.PER_idpersona?.PERR_idrol?.PERR_idrol === PERR_AGENTE.id
      );
      const dataBeneficiarios = resultPersonas.filter(
        (item) =>
          item?.POL_idpoliza?.POL_numpoliza === numPoliza &&
          item?.PER_idpersona?.PERR_idrol?.PERR_idrol === PERR_BENEFICIARIO.id
      );

      // Estableces los datos filtrados
      if (dataPoliza) setdataPoliza(dataPoliza);
      if (dataCobertura) setdataCobertura(dataCobertura);
      if (dataAsegurados) setdataAsegurados(dataAsegurados);
      if (dataContratante) setdataContratante(dataContratante);
      if (dataAgentes) setdataAgentes(dataAgentes);
      if (dataBeneficiarios) setdatabeneficiarios(dataBeneficiarios);
      if (dataReaseguradoras) setdataReaseguradoras(dataReaseguradoras);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getDatosPoliza();
  }, [numPoliza]);

  console.log(dataCobertura);
  console.log(dataPoliza);
  console.log(dataBeneficiarios);
  console.log(dataReseguradoras);

  ///Obtener la fecha de poliza rehabilitada
  const [polizaRehabilitada, setpolizaRehabilitada] = useState("");
  console.log(polizaRehabilitada);

  //////////////////////////////////////////
  //////VARIABLES TRÁMITE GLOBALES//////////
  const idFolio = infoCharola?.RECT_idfolio?.RECT_idfolio;
  const idPoliza = dataPoliza.POL_idpoliza;
  const idProducto = dataPoliza.PRO_idproductos?.PRO_idproducto;
  ////////////////////////////////////////////////

  // Función para actualizar los elementos
  async function actualizarElemento(elementoBase1, elementoBase2, api) {
    // console.log("Elemento de la base 1:", elementoBase1);
    // console.log("Elemento de la base 2:", elementoBase2);
    // Lógica para actualizar el elemento
    if (api === 1) {
      let cobertura = {
        RECT_idfolio: idFolio,
        POL_idpoliza: idPoliza,
        COB_idcobertura: elementoBase2.id_cobertura,
        PRO_idproducto: idProducto,
        MON_idmonedacobertura: elementoBase2.id_moneda,
        PCP_coberturasumaasegurada: elementoBase2.sa,
        PCP_actualizofecha: fechaActSIN,
        PCP_actualizousuario: nombreAnalista,
      };
      putDataToken(
        `polizascoberturasporproductos/get_id/${elementoBase1.id}/`,
        cobertura
      ).then((data) => {
        console.log(`Actualización exitosa cobertura`, data);
      });
    } else if (api === 2) {
      let reaseguradora = {
        REA_idreaseguradora: elementoBase2.id_reaseguradora,
        POL_idpoliza: idPoliza,
        PRA_porcentaje: elementoBase2.porcentaje,
        PRA_importe: elementoBase2.importe,
        MON_idmonedagiroimporte: elementoBase2.id_moneda,
        PRA_actualizofecha: fechaActSIN,
        PRA_actualizousuario: nombreAnalista,
      };
      putDataToken(
        `polizasreaseguro/get_id/${elementoBase1.PRA_idpoliza}/`,
        reaseguradora
      ).then((data) => {
        console.log(`Actualización exitosa reaseguradora`, data);
      });
    } else if (api === 3) {
      let idPersona;

      let personas = {
        PER_nombre: elementoBase2.nombre,
        PER_apellidopaterno: elementoBase2.apellidoPaterno,
        PER_apellidomaterno: elementoBase2.apellidoMaterno,
        PER_fechanacimiento: moment(
          elementoBase2.fecha_nacimiento,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        PERR_idrol: PERR_BENEFICIARIO.id,
        PER_actualizofecha: fechaActSIN,
        PER_actualizousuario: nombreAnalista,
      };

      try {
        const data = await putDataToken(
          `personas/get_id/${elementoBase1.PER_idpersona.PER_idpersona}/`,
          personas
        );
        console.log(`Actualización exitosa beneficiarios`, data);
        idPersona = data.PER_idpersona;

        const dataPersonasxPoliza = await getDataToken(
          `personasxpoliza/get_per/${elementoBase1.PER_idpersona.PER_idpersona}/`
        );
        console.log(dataPersonasxPoliza);

        if (idPersona) {
          const json2 = {
            PER_idpersona: idPersona,
            POL_idpoliza: idPoliza,
            PXP_porcentajebeneficiario: elementoBase2.porcentaje,
            PXP_actualizofecha: fechaActSIN,
            PXP_actualizousuario: nombreAnalista,
          };

          const data2 = await putDataToken(
            `personasxpoliza/get_id/${dataPersonasxPoliza[0].PXP_idpersonaxpoliza}/`,
            json2
          );
          console.log(`Actualización exitosa personasxpolizas`, data2);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }

  // Función para agregar los elementos adicionales
  async function agregarElemento(elementoBase2, api) {
    // console.log("Elemento a agregar de la base 2:", elementoBase2);
    // Lógica para agregar el elemento
    if (api === 1) {
      let cobertura = {
        RECT_idfolio: idFolio,
        POL_idpoliza: idPoliza,
        COB_idcobertura: elementoBase2.id_cobertura,
        PRO_idproducto: idProducto,
        MON_idmonedacobertura: elementoBase2.id_moneda,
        PCP_coberturasumaasegurada: elementoBase2.sa,
        PCP_actualizofecha: fechaActSIN,
        PCP_actualizousuario: nombreAnalista,
      };
      postDataToken(
        `polizascoberturasporproductos/get_listado/`,
        cobertura
      ).then((data) => {
        console.log(`Registro exitoso cobertura`, data);
      });
    } else if (api === 2) {
      let reaseguradora = {
        REA_idreaseguradora: elementoBase2.id_reaseguradora,
        POL_idpoliza: idPoliza,
        PRA_porcentaje: elementoBase2.porcentaje,
        PRA_importe: elementoBase2.importe,
        MON_idmonedagiroimporte: elementoBase2.id_moneda,
        PRA_actualizofecha: fechaActSIN,
        PRA_actualizousuario: nombreAnalista,
      };
      postDataToken(`polizasreaseguro/get_listado/`, reaseguradora).then(
        (data) => {
          console.log(`Registro exitoso reaseguradora`, data);
        }
      );
    } else if (api === 3) {
      let idPersona;

      let personas = {
        PER_nombre: elementoBase2.nombre,
        PER_apellidopaterno: elementoBase2.apellidoPaterno,
        PER_apellidomaterno: elementoBase2.apellidoMaterno,
        PER_fechanacimiento: moment(
          elementoBase2.fecha_nacimiento,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        PERR_idrol: PERR_BENEFICIARIO.id,
        PER_actualizofecha: fechaActSIN,
        PER_actualizousuario: nombreAnalista,
      };

      try {
        const data = await postDataToken(`personas/get_listado/`, personas);
        console.log(`Registro exitoso beneficiarios`, data);
        idPersona = data.PER_idpersona;

        if (idPersona) {
          const json2 = {
            PER_idpersona: idPersona,
            POL_idpoliza: idPoliza,
            PXP_porcentajebeneficiario: elementoBase2.porcentaje,
            PXP_actualizofecha: fechaActSIN,
            PXP_actualizousuario: nombreAnalista,
          };

          const data2 = await postDataToken(
            `personasxpoliza/get_listado/`,
            json2
          );
          console.log(`Registro exitoso personasxpolizas`, data2);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }

  ///////////////////////////////////////
  ////Validar el nombre del agente///////
  //////////////////////////////////////

  // Dividir el nombre completo en partes
  const partesNombre = data[DATA_PM.NOMBRE_AGENTE]?.split(" ");
  // Identificar el nombre, apellido paterno y apellido materno
  let nombreAgente = "";
  let apellidoPaternoAgente = "";
  let apellidoMaternoAgente = "";

  if (partesNombre?.length >= 1) {
    // El primer elemento es el nombre
    nombreAgente = partesNombre[0];

    // El último elemento es el apellido materno
    apellidoMaternoAgente = partesNombre[partesNombre.length - 1];

    // El segundo elemento hasta el penúltimo son apellidos paterno
    if (partesNombre.length > 2) {
      apellidoPaternoAgente = partesNombre.slice(1, -1).join(" ");
    } else if (partesNombre.length === 2) {
      apellidoPaternoAgente = partesNombre[1];
    }
  }

  /////////////////////////Mensajes///////////////////////
  const [mostrarAlerta, setmostrarAlerta] = useState(false);
  const [mostrarConfirmacion, setmostrarConfirmacion] = useState(false);
  const [banderaEjecutado, setbanderaEjecutado] = useState(false);
  const [banderaFinal, setbanderaFinal] = useState(false);

  const mostrarAlert = () => {
    if (banderaFinal) {
      setbanderaEjecutado(true);
    } else {
      setmostrarAlerta(true);
    }
  };

  const mostrarConfirm = async () => {
    // PUT Y POST BENEFICIARIOS
    let apiBeneficiarios = 3;
    if (dataBeneficiarios.length === beneficiariosActualizar.length) {
      // Actualizar todos los elementos
      for (let i = 0; i < dataBeneficiarios.length; i++) {
        actualizarElemento(
          dataBeneficiarios[i],
          beneficiariosActualizar[i],
          apiBeneficiarios
        ); // Función para actualizar los elementos
      }
    } else if (dataBeneficiarios.length < beneficiariosActualizar.length) {
      // Actualizar los elementos de la base 1 y agregar los elementos adicionales de la base 2
      for (let i = 0; i < dataBeneficiarios.length; i++) {
        actualizarElemento(
          dataBeneficiarios[i],
          beneficiariosActualizar[i],
          apiBeneficiarios
        ); // Función para actualizar los elementos
      }
      for (
        let i = dataBeneficiarios.length;
        i < beneficiariosActualizar.length;
        i++
      ) {
        agregarElemento(beneficiariosActualizar[i], apiBeneficiarios); // Función para agregar los elementos adicionales
      }
    }
    // PUT Y POST REASEGURADORAS
    let apiReaseguradoras = 2;
    if (dataReseguradoras.length === reaseguradorasActualizar.length) {
      // Actualizar todos los elementos
      for (let i = 0; i < dataReseguradoras.length; i++) {
        actualizarElemento(
          dataReseguradoras[i],
          reaseguradorasActualizar[i],
          apiReaseguradoras
        ); // Función para actualizar los elementos
      }
    } else if (dataReseguradoras.length < reaseguradorasActualizar.length) {
      // Actualizar los elementos de la base 1 y agregar los elementos adicionales de la base 2
      for (let i = 0; i < dataReseguradoras.length; i++) {
        actualizarElemento(
          dataReseguradoras[i],
          reaseguradorasActualizar[i],
          apiReaseguradoras
        ); // Función para actualizar los elementos
      }
      for (
        let i = dataReseguradoras.length;
        i < reaseguradorasActualizar.length;
        i++
      ) {
        agregarElemento(reaseguradorasActualizar[i], apiReaseguradoras); // Función para agregar los elementos adicionales
      }
    }
    // PUT Y POST COBERTURAS
    let apiCoberturas = 1;
    if (dataCobertura.length === coberturasActualizar.length) {
      // Actualizar todos los elementos
      for (let i = 0; i < dataCobertura.length; i++) {
        actualizarElemento(
          dataCobertura[i],
          coberturasActualizar[i],
          apiCoberturas
        ); // Función para actualizar los elementos
      }
    } else if (dataCobertura.length < coberturasActualizar.length) {
      // Actualizar los elementos de la base 1 y agregar los elementos adicionales de la base 2
      for (let i = 0; i < dataCobertura.length; i++) {
        actualizarElemento(
          dataCobertura[i],
          coberturasActualizar[i],
          apiCoberturas
        ); // Función para actualizar los elementos
      }
      for (let i = dataCobertura.length; i < coberturasActualizar.length; i++) {
        agregarElemento(coberturasActualizar[i], apiCoberturas); // Función para agregar los elementos adicionales
      }
    }
    //PUT POLIZA
    let poliza = {
      POL_entidadlegal:
        dataPoliza?.POL_entidadlegal === data[DATA_PM.ENTIDAD_LEGAL]
          ? dataPoliza?.POL_entidadlegal
          : data[DATA_PM.ENTIDAD_LEGAL],
      POL_tipodeseguro:
        dataPoliza?.POL_tipodeseguro === data[DATA_PM.TIPO_SEGURO]
          ? dataPoliza?.POL_tipodeseguro
          : data[DATA_PM.TIPO_SEGURO],
      POL_numpoliza:
        dataPoliza?.POL_numpoliza === data[DATA_PM.POLIZA]
          ? dataPoliza?.POL_numpoliza
          : data[DATA_PM.POLIZA],
      POL_polizapadre:
        dataPoliza?.POL_polizapadre === data[DATA_PM.POLIZA_PADRE]
          ? dataPoliza?.POL_polizapadre
          : data[DATA_PM.POLIZA_PADRE],
      MON_idmonedas:
        dataPoliza?.MON_idmonedas?.MON_idmoneda === data[DATA_PM.ID_MONEDA]
          ? dataPoliza?.MON_idmonedas?.MON_idmoneda
          : data[DATA_PM.ID_MONEDA],
      POL_polizapahasta:
        dataPoliza?.POL_polizapahasta === data[DATA_PM.PAGADO_HASTA]
          ? dataPoliza?.POL_polizapahasta
          : moment(data[DATA_PM.PAGADO_HASTA], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
      EST_idestatuspoliza:
        dataPoliza?.EST_idestatuspoliza?.EST_idestatuspoliza ===
        data[DATA_PM.ID_ESTATUS_POLIZA]
          ? dataPoliza?.EST_idestatuspoliza?.EST_idestatuspoliza
          : data[DATA_PM.ID_ESTATUS_POLIZA],
      POL_aniosduracionpoliza:
        dataPoliza?.POL_aniosduracionpoliza === data[DATA_PM.DURACION_POLIZA]
          ? dataPoliza?.POL_aniosduracionpoliza
          : data[DATA_PM.DURACION_POLIZA],
      POL_pathpolizaorigen:
        dataPoliza?.POL_pathpolizaorigen === data[DATA_PM.PATH_POLIZA]
          ? dataPoliza?.POL_pathpolizaorigen
          : data[DATA_PM.PATH_POLIZA],
      POL_polizafoinversión:
        dataPoliza?.POL_polizafoinversión ===
        data[DATA_PM.FONDO_INVERSION].toString()
          ? dataPoliza?.POL_polizafoinversión
          : data[DATA_PM.FONDO_INVERSION].toString(),
      POL_polizapréstamo:
        dataPoliza?.POL_polizapréstamo === data[DATA_PM.PRESTAMO]
          ? dataPoliza?.POL_polizapréstamo
          : data[DATA_PM.PRESTAMO],
      CHN_idcanales:
        dataPoliza?.CHN_idcanales?.CHN_idcanal === data[DATA_PM.ID_CANAL]
          ? dataPoliza?.CHN_idcanales?.CHN_idcanal
          : data[DATA_PM.ID_CANAL],
      OF_idoficinas:
        dataPoliza?.OF_idoficinas?.OF_idoficina === data[DATA_PM.ID_OFICINA]
          ? dataPoliza?.OF_idoficinas?.OF_idoficina
          : data[DATA_PM.ID_OFICINA],
      ESTA_idestado:
        dataPoliza?.ESTA_idestado?.ESTA_idestado === data[DATA_PM.ID_ESTADO]
          ? dataPoliza?.ESTA_idestado?.ESTA_idestado
          : data[DATA_PM.ID_ESTADO],
      POL_polizarematemática:
        dataPoliza?.POL_polizarematemática ===
        data[DATA_PM.RESERVA_MATEMATICA].toString()
          ? dataPoliza?.POL_polizarematemática
          : data[DATA_PM.RESERVA_MATEMATICA].toString(),
      POL_polizareaseguro:
        dataPoliza?.POL_polizareaseguro === data[DATA_PM.REASEGURO].toString()
          ? dataPoliza?.POL_polizareaseguro
          : data[DATA_PM.REASEGURO].toString(),
      POL_polizaprima:
        dataPoliza?.POL_polizaprima === data[DATA_PM.PRIMA].toString()
          ? dataPoliza?.POL_polizaprima
          : data[DATA_PM.PRIMA].toString(),
      FPAG_idformasdepago:
        dataPoliza?.FPAG_idformasdepago?.FPAG_idformapago ===
        data[DATA_PM.ID_FORMA_PAGO]
          ? dataPoliza?.FPAG_idformasdepago?.FPAG_idformapago
          : data[DATA_PM.ID_FORMA_PAGO],
      POL_polizafinvigencia:
        dataPoliza?.POL_polizafinvigencia === data[DATA_PM.FECHA_FIN_VEGENCIA]
          ? dataPoliza?.POL_polizafinvigencia
          : moment(data[DATA_PM.FECHA_FIN_VEGENCIA], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
      POL_polizafecemisión:
        dataPoliza?.POL_polizafecemisión === data[DATA_PM.FECHA_EMISION_POLIZA]
          ? dataPoliza?.POL_polizafecemisión
          : moment(data[DATA_PM.FECHA_EMISION_POLIZA], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
      PRO_idproductos:
        dataPoliza?.PRO_idproductos?.PRO_idproducto ===
        data[DATA_PM.PRODUCTO_COMERCIAL]
          ? dataPoliza?.PRO_idproductos?.PRO_idproducto
          : data[DATA_PM.PRODUCTO_COMERCIAL],
      POL_polizarehabilitada: polizaRehabilitada ? polizaRehabilitada : null,
      POL_actualizofecha: fechaActSIN,
      POL_actualizousuario: nombreAnalista,
    };
    const dataPolizas = await putDataToken(
      `polizas/get_id/${dataPoliza?.POL_idpoliza}/`,
      poliza
    );
    console.log(`Actualización exitosa de poliza`, dataPolizas);
    console.log(poliza);

    //PUT ASEGURADO
    let asegurado = {
      PER_nombre:
        dataAsegurados?.PER_idpersona?.PER_nombre ===
        data[DATA_PM.NOMBRE_ASEGURADO]
          ? dataAsegurados?.PER_idpersona?.PER_nombre
          : data[DATA_PM.NOMBRE_ASEGURADO],
      PER_apellidopaterno:
        dataAsegurados?.PER_idpersona?.PER_apellidopaterno ===
        data[DATA_PM.APELLIDO_PATERNO_ASE]
          ? dataAsegurados?.PER_idpersona?.PER_apellidopaterno
          : data[DATA_PM.APELLIDO_PATERNO_ASE],
      PER_apellidomaterno:
        dataAsegurados?.PER_idpersona?.PER_apellidomaterno ===
        data[DATA_PM.APELLIDO_MATERNO_ASE]
          ? dataAsegurados?.PER_idpersona?.PER_apellidomaterno
          : data[DATA_PM.APELLIDO_MATERNO_ASE],
      PER_sexo:
        dataAsegurados?.PER_idpersona?.PER_sexo === data[DATA_PM.GENERO_SEXO]
          ? dataAsegurados?.PER_idpersona?.PER_sexo
          : data[DATA_PM.GENERO_SEXO],
      PER_CURP:
        dataAsegurados?.PER_idpersona?.PER_CURP === data[DATA_PM.CURP_ASEGURADO]
          ? dataAsegurados?.PER_idpersona?.PER_CURP
          : data[DATA_PM.CURP_ASEGURADO],
      PER_RFC:
        dataAsegurados?.PER_idpersona?.PER_RFC === data[DATA_PM.RFC_ASEGURADO]
          ? dataAsegurados?.PER_idpersona?.PER_RFC
          : data[DATA_PM.RFC_ASEGURADO],
      PER_fechanacimiento:
        dataAsegurados?.PER_idpersona?.PER_fechanacimiento ===
        data[DATA_PM.FECHA_NAC_ASEGURADO]
          ? dataAsegurados?.PER_idpersona?.PER_fechanacimiento
          : moment(data[DATA_PM.FECHA_NAC_ASEGURADO], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
      PER_edadtramite:
        dataAsegurados?.PER_idpersona?.PER_edadtramite ===
        data[DATA_PM.EDAD_EMISION]
          ? dataAsegurados?.PER_idpersona?.PER_edadtramite
          : data[DATA_PM.EDAD_EMISION],
      PER_actualizofecha: fechaActSIN,
      PER_actualizousuario: nombreAnalista,
    };
    const dataAsegurado = await putDataToken(
      `personas/get_id/${dataAsegurados?.PER_idpersona?.PER_idpersona}/`,
      asegurado
    );
    console.log(`Actualización exitosa de personas-asegurado`, dataAsegurado);

    //PUT CONTRATANTE
    let contratante = {
      PER_nombre:
        dataContratante?.PER_idpersona?.PER_nombre ===
        data[DATA_PM.NOMBRE_CONTRATANTE]
          ? dataContratante?.PER_idpersona?.PER_nombre
          : data[DATA_PM.NOMBRE_CONTRATANTE],
      PER_apellidopaterno:
        dataContratante?.PER_idpersona?.PER_apellidopaterno ===
        data[DATA_PM.APELLIDO_PATERNO_CONT]
          ? dataContratante?.PER_idpersona?.PER_apellidopaterno
          : data[DATA_PM.APELLIDO_PATERNO_CONT],
      PER_apellidomaterno:
        dataContratante?.PER_idpersona?.PER_apellidomaterno ===
        data[DATA_PM.APELLIDO_MATERNO_CONT]
          ? dataContratante?.PER_idpersona?.PER_apellidomaterno
          : data[DATA_PM.APELLIDO_MATERNO_CONT],
      PER_actualizofecha: fechaActSIN,
      PER_actualizousuario: nombreAnalista,
    };
    const dataContratantes = await putDataToken(
      `personas/get_id/${dataContratante?.PER_idpersona?.PER_idpersona}/`,
      contratante
    );
    console.log(
      `Actualización exitosa de personas-contratante`,
      dataContratantes
    );

    //PUT AGENTES
    let agente = {
      PER_nombre:
        dataAgentes?.PER_idpersona?.PER_nombre === nombreAgente
          ? dataAgentes?.PER_idpersona?.PER_nombre
          : nombreAgente,
      PER_apellidopaterno:
        dataAgentes?.PER_idpersona?.PER_apellidopaterno ===
        apellidoPaternoAgente
          ? dataAgentes?.PER_idpersona?.PER_apellidopaterno
          : apellidoPaternoAgente,
      PER_apellidomaterno:
        dataAgentes?.PER_idpersona?.PER_apellidomaterno ===
        apellidoMaternoAgente
          ? dataAgentes?.PER_idpersona?.PER_apellidomaterno
          : apellidoMaternoAgente,
      PER_clave:
        dataAgentes?.PER_idpersona?.PER_clave === data[DATA_PM.CLAVE_AGENTE]
          ? dataAgentes?.PER_idpersona?.PER_clave
          : data[DATA_PM.CLAVE_AGENTE],
      // OFXAGEN_idoficinaxagencia:
      //   dataAgentes?.PER_idpersona?.OFXAGEN_idoficinaxagencia?
      //     .OFXAGEN_idoficinaxagencia === data[DATA_PM.DA_AGENTE]
      //     ? dataAgentes?.PER_idpersona?.OFXAGEN_idoficinaxagencia?
      //         .OFXAGEN_idoficinaxagencia
      //     : data[DATA_PM.DA_AGENTE],
      PER_actualizofecha: fechaActSIN,
      PER_actualizousuario: nombreAnalista,
    };
    const dataAgente = await putDataToken(
      `personas/get_id/${dataAgentes?.PER_idpersona?.PER_idpersona}/`,
      agente
    );
    console.log(`Actualización exitosa de personas-agente`, dataAgente);

    setmostrarConfirmacion(true);
    setmostrarAlerta(false);
  };

  const cerrarAlert = () => {
    setmostrarAlerta(false);
  };

  const msjFinal = () => {
    setbanderaFinal(true);
    handleExpandClick();
  };

  const ultimoAlert = () => {
    setbanderaEjecutado(false);
    handleExpandClick();
  };

  console.log(banderaFinal);

  return (
    <>
      {banderaEjecutado && (
        <DialogValidaciones
          title={
            <FormattedMessage
              id="regisMsgInf"
              defaultMessage="Mensaje informativo"
            />
          }
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={
            <FormattedMessage
              id="regisReali"
              defaultMessage="El registro ya fue realizado."
            />
          }
          onClick={ultimoAlert}
        />
      )}
      {mostrarConfirmacion && (
        <DialogValidaciones
          title={
            <FormattedMessage
              id="regisGenVrs"
              defaultMessage="GENERACIÓN DE VERSIÓN 1 DE PÓLIZA"
            />
          }
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={
            <FormattedMessage
              id="recepFeCre"
              defaultMessage="Fecha de creación: {fecha}"
              values={{ fecha: moment(fechaEmpAn).format(formatofecha) }}
            />
          }
          description2={
            <FormattedMessage
              id="regisHoraCrea"
              defaultMessage="Hora de creación: {hora}"
              values={{ hora: horaActual }}
            />
          }
          description3={
            <FormattedMessage
              id="regisAnalista"
              defaultMessage="Analista: {analista}"
              values={{ analista: nombreAnalista }}
            />
          }
          onClick={msjFinal}
        />
      )}
      {mostrarAlerta && (
        <Dialogtwoboton
          title={
            <FormattedMessage
              id="regisMensajeConf"
              defaultMessage="Mensaje de confirmación"
            />
          }
          description={
            <FormattedMessage
              id="regisMensajeOpc"
              defaultMessage="¿Esta seguro de realizar esta operación?"
            />
          }
          firstButton={<FormattedMessage id="globSi" defaultMessage="Si" />}
          secondButton={<FormattedMessage id="globNo" defaultMessage="No" />}
          onClick={mostrarConfirm}
          onClick2={cerrarAlert}
        />
      )}
      <div>
        <Card sx={{ width: "100%" }}>
          <CardHeader
            onClick={handleExpandClick}
            action={
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                style={{ color: "#fff" }}
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
            style={{
              backgroundColor: "#00203b",
              color: "#fff",
              cursor: "pointer",
            }}
            title={
              <h1-header-card-polizas>
                <FormattedMessage
                  id="globNumPol"
                  defaultMessage="Número de póliza"
                />{" "}
                - {numPoliza}
              </h1-header-card-polizas>
            }
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <h2-dialog-description>
                <strong>
                  <FormattedMessage
                    id="regExtPo"
                    defaultMessage="Extracción de datos de la póliza del PM."
                  />
                </strong>
              </h2-dialog-description>
              <br />
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                }}
              >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  <Tab
                    label={
                      <FormattedMessage
                        id="regDataPo"
                        defaultMessage="Datos de la póliza"
                      />
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="regDataCo"
                        defaultMessage="Coberturas de la póliza"
                      />
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="regDataRe"
                        defaultMessage="Reaseguradoras de la póliza"
                      />
                    }
                    {...a11yProps(2)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="regDataAseg"
                        defaultMessage="Datos del asegurado y contratante"
                      />
                    }
                    {...a11yProps(3)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="regDataBene"
                        defaultMessage="Datos de los beneficiarios"
                      />
                    }
                    {...a11yProps(4)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="regDataAgen"
                        defaultMessage="Datos del agente"
                      />
                    }
                    {...a11yProps(5)}
                  />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <TabDatosPoliza
                    dataPoliza={data}
                    polizaRehabilitada={(e) => setpolizaRehabilitada(e)}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <TabDatosCoberturas dataPoliza={data} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <TabDatosReservas dataPoliza={data} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <TabDatosAsegurado dataPoliza={data} dataPoliza2={data} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <TabDatosBeneficiarios dataPoliza={data} />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <TabDatosAgente dataPoliza={data} />
                </TabPanel>
              </Box>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={"auto"}>
                  <ButtonVino
                    label={
                      <FormattedMessage
                        id="globGuarda"
                        defaultMessage="Guardar"
                      />
                    }
                    onClick={mostrarAlert}
                  ></ButtonVino>
                </Grid>
              </Grid>
              <br />
              <br />
            </CardContent>
          </Collapse>
        </Card>
      </div>
    </>
  );
}

/* Brandon Hernandez Rocha 11/2022 */
import React from "react";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";
import { useLocation } from "react-router-dom";
import TablaDetalleFolio from "./Elements/Tables/TablaDetalleFolio";
import { DetalleFolio } from "./Elements/DetalleFolio";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid } from "@mui/material";
//idioma
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import { getDataToken, postDataToken } from "../../api/dataToken";
import { Loading2 } from "../Tools/Progress/Loading2";

const CompDictamenDetalleFolio = () => {
    const [isLoading, setIsLoading] = useState(true);
    const arrayBreadcrumbs = [{ 'key': 1, 'name': <FormattedMessage id="glob.inicio" defaultMessage="Inicio" />, 'to': '/menu' }, { 'key': 2, 'name': <FormattedMessage id="menu.dictamen" defaultMessage="Dictamen" />, 'to': '/dictamen' }]
    const location = useLocation();
    const polizas = location.state.row;
    console.log("Polizas: ", polizas)
    const today = new Date();
    /****** Código Usuario que actualizó *******/
    const [user, setUser] = useState("");
    //GET A API 
    const getDataUser = async () => {
        //Resultados de la api documentacion documentosxtiposiniestrostramite
        var result = await getDataToken("login/get_correo/" + localStorage.getItem("SNYTLOG_email") + "/");
        setUser(result?.SNYTLOG_username + " " + result?.SNYTLOG_surnamedad + " " + result?.SNYTLOG_surnamemom);
        //getDataPolCoberProd();
    };
    useEffect(() => {
        getDataUser();
    }, []);
    useEffect(() => {
        if (user !== "") {
            getDataPolCoberProd();
        }
    }, [user]);

    /* Funcion para crear el registro de la reserva con valores default*/

    // Validar si ya existe un registro de reserva de esa póliza y cobertura. Filtramos hasta buscar el objeto y obtener el numero de poliza y id cobertura

    const getDataPolCoberProd = async () => {
        try {
            let dataPCP = await getDataToken("coberturaspolizasportramite/get_folio/" + polizas[0].RECT_idfolio.RECT_idfolio + "/"); //cambiar por un filtro por folio en api 
            if (dataPCP) {
                if (dataPCP.length > 0) {
                    for (const element of dataPCP) {
                        //recorrer las polizas del folio 
                        for (const object of polizas) {
                            if (element.RECT_idfolio.RECT_idfolio === object.RECT_idfolio.RECT_idfolio
                                && element.POL_idpoliza.POL_idpoliza === object.POL_idpoliza.POL_idpoliza) {
                                //Validar si existe un registro de reserva con esta poliza y cobertura
                                let resp = await getDataToken("reservasporpolizaporcoberturas/get_IDPCP/" + element.CPT_idcoberturapolizatramite + "/");
                                if (resp.length > 0) { // Existe un registro de reserva
                                    console.log("Ya existe un registro de reserva", resp);
                                    setIsLoading(false);
                                    //validar que se haya hecho post de reservas ajustes 
                                    let resp2 = await getDataToken("reservaajustes/get_listado/");
                                    let buscarReserAjus = resp2.find((element) => element?.RPC_idreservapolizacob?.id == resp[0].id)
                                    if (buscarReserAjus) {
                                        //Ya existe el registro de reserva con valores por default
                                        setIsLoading(false);
                                    } else {
                                        console.log("Realizar post reservas ajustes ")
                                        let conceptos = await getDataToken(`conceptosreservaajustes/get_listado/`);
                                        if (conceptos.length > 0) {
                                            for (const concepto of conceptos) {
                                                let jsonReserAjus = {
                                                    "RPC_idreservapolizacob": resp[0].id,
                                                    "CREVAJ0_idconceptoreservaajuste": concepto.CREVAJ0_idconceptoreservaajuste,
                                                    "REAJ0_importe": 0,
                                                    "CREVAJ0_actualizousuario": user
                                                }
                                                console.log("jsonReserAju", jsonReserAjus);
                                                let result = await postDataToken(`reservaajustes/get_listado/`, jsonReserAjus);
                                                if (result) {
                                                    console.log("Post reserva ajustes realizado")
                                                } else {
                                                    console.log("error al hacer post reservas ajustes")
                                                }
                                                setIsLoading(false);
                                            }
                                        }
                                    }
                                } else { // No existe un registro de reserva, crear registro. 
                                    //Aqui el codigo post para registrar la reserva
                                    let jsonReservasPolCober = {
                                        CPT_idcoberturapolizatramite: element.CPT_idcoberturapolizatramite,
                                        MON_idmoneda: element.POL_idpoliza?.PRO_idproductos?.MON_idmonedas?.MON_idmoneda,
                                        RPC_actualizofecha: today,
                                        RPC_actualizousuario: user,
                                        MON_idmoneda: element.MON_idmoneda.MON_idmoneda,

                                    }
                                    console.log("Aux", jsonReservasPolCober)
                                    let resp = await postDataToken("reservasporpolizaporcoberturas/get_listado/", jsonReservasPolCober);
                                    console.log("resp post reservasporpolizaporcoberturas", resp)
                                    if (resp) {// si es existoso, hacer los post de reserva ajustes por cada accesorio 
                                        let conceptos = await getDataToken(`conceptosreservaajustes/get_listado/`);
                                        if (conceptos.length > 0) {
                                            for (const concepto of conceptos) {
                                                let jsonReserAjus = {
                                                    "RPC_idreservapolizacob": resp.id,
                                                    "CREVAJ0_idconceptoreservaajuste": concepto.CREVAJ0_idconceptoreservaajuste,
                                                    "REAJ0_importe": 0,
                                                    "CREVAJ0_actualizousuario": user
                                                }
                                                console.log("jsonReserAju", jsonReserAjus);
                                                let result = await postDataToken(`reservaajustes/get_listado/`, jsonReserAjus);
                                                if (result) {
                                                    console.log("Post reserva ajustes realizado")
                                                } else {
                                                    console.log("error al hacer post reservas ajustes")
                                                }
                                            }
                                        }
                                        setIsLoading(false);
                                    } else {
                                        alert("Algo ha salido mal");
                                        setIsLoading(false);
                                    }
                                    return true;
                                }
                            }
                        }

                    }
                }
            };
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


    return (
        <div className='div-parent'>
            <>
                <div className='div-breadcrumbs'>
                    <CustomSeparatorDinamic breadcrumbs={arrayBreadcrumbs} name={`Detalle folio`} />
                </div>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                    <ReturnPage to='/dictamen' />
                </Grid>
                <div className='div-container'>
                    {isLoading ? <Loading2 /> : <>
                        <DetalleFolio infoRow={polizas} />
                        <TablaDetalleFolio infoPolizas={polizas} />
                    </>
                    }
                    {/* <DetalleFolio infoRow={polizas} />
                    <TablaDetalleFolio infoPolizas={polizas} /> */}
                </div>
            </>
        </div>

    );
}
export default CompDictamenDetalleFolio
